import { create } from "zustand";

export enum Role {
  ADMIN = "admin",
  MODERATOR = "moderator",
  USER = "user"
}

export enum PaymentProvider {
  STRIPE = "stripe",
  APPLE = "apple",
  HALF_YEARLY = "google"
}

export enum ActivityLevel {
  SEDENTARY = "Sedentary",
  LIGHTLY_ACTIVE = "Lightly Active",
  MODERATELY_ACTIVE = "Moderately Active",
  VERY_ACTIVE = "Very Active"
}

export enum Gender {
  MALE = "male",
  FEMALE = "female"
}

export enum UserGoal {
  STAY_FIT = "Stay Fit",
  LOSE_WEIGHT = "Lose Weight",
  GAIN_MUSCLE = "Gain Muscle"
}

export enum WorkoutStyle {
  WEIGHTED = "Weighted",
  BODY_WEIGHT = "Body Weight"
}

export enum SubscriptionType {
  MONTHLY = "monthly",
  YEARLY = "yearly",
  HALF_YEARLY = "half_yearly",
  LIFETIME = "lifetime"
}

interface State {
  userData: {
    socialLogins: {
      facebook: boolean;
      google: boolean;
      apple: boolean;
    },
    "_id": string,
    "email": string,
    "hasCompletedOnboarding": boolean,
    "role": Role,
    "isVerified": boolean,
    "createdAt": Date,
    "updatedAt": Date,
    "__v": 0,
    "verifiedAt": Date,
    "paymentProvider": PaymentProvider,
    "activityLevel": ActivityLevel,
    "age": number,
    "bmi": number,
    "caloriesPerDay": number,
    "firstName": string,
    "gender": Gender,
    "height": number,
    "lastName": string,
    "userGoal": UserGoal,
    "weight": number,
    "workoutStyle": WorkoutStyle,
    "isSubscriber": boolean,
    "subscriptionType": SubscriptionType
  } | null,
  setUserData: (userData: State["userData"]) => void
}

const useStore = create<State>()(set => ({
  userData: null,
  setUserData: (userData: State["userData"]) => set({ userData }),
}));

export default useStore;