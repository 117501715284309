import {
  Avatar,
  Box,
  CssBaseline,
  Divider,
  Grid,
  IconButton,
  Link,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import logo from "../../assets/img/GFWU_logo-06_2.png";
import AppStoreLogo from "../../assets/img/appStore.png";
import PlayStoreLogo from "../../assets/img/playStore.png";
import Logout from "@mui/icons-material/Logout";
import { useLoggedInUpdate } from "../../utils/loggedInContext";
import { useNavigate } from "react-router-dom";
import API_CALL from "../../services";
import useStore from "../../store";

const Welcome: React.FC = () => {
  const setloginStatus = useLoggedInUpdate();
  const { userData: user, setUserData } = useStore()
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const isSmaller = useMediaQuery("(max-width:1000px)");

  async function handleLogout() {
    try {
      const res = await API_CALL.logout();
      if (res.data.status === "success") {
        setUserData(null)
        setloginStatus("loggedout");
        navigate("/");
      }
    } catch (err: any) {
      console.error("Logout error:", err);
    }
  }

  function formatSubscriptionType(subscriptionType?: string): string {
    switch (subscriptionType) {
      case "Monthly":
        return "Monthly";
      case "Yearly":
        return "Yearly";
      case "HALF_YEARLY":
        return "Half Yearly";
      case "LIFETIME":
        return "Lifetime";
      default:
        return subscriptionType || "";
    }
  }
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  return (
    <>
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          minHeight: "100vh",
          padding: "0 16px", // Add padding for small screens
        }}
      >
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          sx={{ width: "100%", mb: 3 }}
        >
          <Grid item xs={6} sm={6} display="flex">
            <Box display={"flex"} alignItems={"center"} ml={3} flex={1}>
              <img
                src={logo}
                alt="GoFitWithUs Logo"
                style={{
                  width: isSmallScreen ? "200px" : "300px",
                  marginTop: "32px",
                }}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={6}
            sm={6}
            display="flex"
            justifyContent={{ xs: "flex-end", sm: "flex-end" }}
            sx={{
              position: { xs: "relative", sm: "initial" },
              mt: { xs: 2, sm: 0 },
            }}
          >
            <Tooltip title="Account settings">
              <IconButton
                onClick={handleMenuClick}
                size="small"
                aria-controls={menuOpen ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={menuOpen ? "true" : undefined}
                sx={{
                  "&:hover": {
                    backgroundColor: "inherit",
                  },
                }}
              >
                <ListItem>
                  <ListItemAvatar>
                    <Avatar src="/broken-image.jpg" />
                  </ListItemAvatar>
                  {!isSmallScreen && (
                    <ListItemText primary="User" secondary={user?.email} />
                  )}
                </ListItem>
              </IconButton>
            </Tooltip>
            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={menuOpen}
              onClose={handleMenuClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                  },
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem
                sx={{
                  "&:hover": {
                    backgroundColor: "transparent",
                    cursor: "default",
                  },
                }}
              >
                <ListItem>
                  <ListItemText
                    primary="Subscription type"
                    secondary={
                      formatSubscriptionType(user?.subscriptionType) ||
                      "Not subscribed"
                    }
                  />
                </ListItem>
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
        <Box
          display="flex"
          flexDirection={isSmaller ? "column" : "row"}
          justifyContent="space-around"
          width="100%"
          mt={5}
          px={isSmaller ? 0 : 4} // Adjust horizontal padding for small screens
        >
          <Box
            width={isSmaller ? "100%" : "50%"}
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt={isSmaller ? 10 : 0}
            p={isSmaller ? 2 : 5} // Adjust padding for small screens
          >
            <Typography
              variant={isSmallScreen ? "h2" : "h1"}
              color="#ccc"
              fontWeight={600}
            >
              Welcome
            </Typography>
            <Typography
              variant={isSmallScreen ? "h4" : "h3"}
              color="#ccc"
              textAlign="center"
              width="100%"
              sx={{ mt: 4, fontWeight: 500 }}
            >
              Transform your Lifestyle with Go
              <Box component="span" sx={{ color: "#ED1C24", fontWeight: 600 }}>
                Fit
              </Box>
              WithUs App
            </Typography>
            <Box display="flex" columnGap={2} mt={6}>
              <Link
                href="https://apps.apple.com/app/gfwu/id6478832493"
                target="_blank"
                sx={{
                  display: "inline-block",
                  "& img": {
                    transition: "transform 0.3s ease",
                  },
                  "&:hover img": {
                    transform: "translateY(-5px)",
                  },
                }}
              >
                <img
                  src={AppStoreLogo}
                  alt="appStore"
                  width={184}
                  height={55}
                />
              </Link>
              <Link
                href="https://play.google.com/store/apps/details?id=com.gfwu.app"
                target="_blank"
                sx={{
                  display: "inline-block",
                  "& img": {
                    transition: "transform 0.3s ease",
                  },
                  "&:hover img": {
                    transform: "translateY(-5px)",
                  },
                }}
              >
                <img
                  src={PlayStoreLogo}
                  alt="playStore"
                  width={184}
                  height={55}
                />
              </Link>
            </Box>
          </Box>
          {!isSmaller && (
            <Box
              width="50%"
              display="flex"
              justifyContent="center"
              pr={8} // Adjust right padding for small screens
            >
              <img
                src="https://gofitwithus.com/wp-content/uploads/2023/09/Group-155-1-e1695122995154.png"
                alt="mobile"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default Welcome;
