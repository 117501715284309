import React, { useMemo, useState } from "react";
import { TextField, Button, Grid, Box, AlertColor, Alert } from "@mui/material";
import { useLocation } from "react-router-dom";

// Import your logo URL

import API_CALL from "../../services";
import { AuthProps } from "../Authentication";
type Error = {
  state?: null | AlertColor;
  message?: string;
};
const ResetPassword = ({ navigateToAuth }: AuthProps): JSX.Element => {
  const location = useLocation();
  const [alert, setAlert] = useState<Error>({ state: null, message: "" });
  const [loading, setLoading] = useState<boolean>(false);

  const search = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);

  const otpQuery = search.get("otp") as string;

  const resetPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);

    const data = {
      password: formData.get("password") as string,
      passwordConfirm: formData.get("passwordConfirm") as string,
      otp: otpQuery,
    };
    if (!data.password) {
      setAlert({ state: "error", message: "Password cannot be empty" });
    } else if (!data.passwordConfirm) {
      setAlert({ state: "error", message: "Confirm password cannot be empty" });
    } else {
      if (e) setLoading(true);
      try {
        await API_CALL.resetPassword(data);
        navigateToAuth({ page: "login" });
        // setAlert({ state: "success", message: res.data.message });
        if (e) setLoading(false);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        setLoading(false);
        console.log(err.response);
        // setAlert({ state: "error", message: err.response });
      }
    }
  };
  const clearAlert = () => {
    setAlert((prev) => ({ state: prev === null ? undefined : null }));
  };

  return (
    <Box
      component="form"
      onSubmit={resetPassword}
      onChange={clearAlert}
      method="post"
      sx={{ mt: 1, width: "100%" }}
    >
      <TextField
        margin="normal"
        required
        fullWidth
        id="password"
        label="Password"
        name="password"
        autoComplete="password"
        type="password"
        autoFocus
        sx={{
          "& .MuiInputBase-root": {
            backgroundColor: "transparent",
            color: "white",
          },
          "& .MuiInputLabel-root": {
            color: "white",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "white",
            },
            "&:hover fieldset": {
              borderColor: "white",
            },
            "&.Mui-focused fieldset": {
              borderColor: "white",
            },
          },
        }}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="passwordConfirm"
        label="Confirm Pasword"
        name="passwordConfirm"
        autoComplete="passwordConfirm"
        type="password"
        autoFocus
        sx={{
          "& .MuiInputBase-root": {
            backgroundColor: "transparent",
            color: "white",
          },
          "& .MuiInputLabel-root": {
            color: "white",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "white",
            },
            "&:hover fieldset": {
              borderColor: "white",
            },
            "&.Mui-focused fieldset": {
              borderColor: "white",
            },
          },
        }}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="error"
        sx={{ mt: 3, mb: 2, height: "52px" }}
        disabled={loading}
      >
        {loading ? "Sending email" : "Reset Your Password"}
      </Button>
      {!loading && alert?.state && (
        <Alert variant="standard" severity={alert.state}>
          {alert.message}
        </Alert>
      )}
      <Box
        sx={{
          margin: "20px -40px -52px -40px",
        }}
        bgcolor={"#383542c4"}
      >
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60px",
          }}
        >
          <Grid item>
            <Button
              onClick={() => navigateToAuth({ page: "login" })}
              sx={{
                color: "#f1f1ff",

                fontSize: "14px",
                textTransform: "none",
                "&:hover": {
                  color: "#ADD8E6", // Light blue shade
                  cursor: "pointer",
                  textDecoration: "underline",
                  backgroundColor: "inherit", // To prevent background change on hover
                },
              }}
            >
              Back to login
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ResetPassword;
