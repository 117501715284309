import { Outlet, RouteObject } from "react-router-dom";
import * as path from "./path";
import Welcome from "../pages/Welcome";

import PrivateRoutes from "./PrivateRoutes";
import ProtectedRoutes from "./ProtectedRoutes";
import Plans from "../pages/plans";

const router: RouteObject[] = [
  {
    path: "/",
    element: <Outlet />,
    children: [
      {
        element: <PrivateRoutes />,
        children: [
          {
            path: "/",
            element: <ProtectedRoutes />,
            children: [
              {
                index: true,
                element: <Plans/>,
              },
              {
                path: path.welcomePath,
                element: <Welcome />,
              },
              {
                path: path.plansPath,
                element: <Plans />,
              },
            ],
          },
        ],
      },
    ],
  },
];
export default router;
