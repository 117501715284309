import React, { Fragment, useEffect } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, useRoutes } from "react-router-dom";
import router from "./router";
import "./index.css";
import LoggedInProvider, { useLoggedIn, useLoggedInUpdate } from "./utils/loggedInContext";
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./utils/theme";
import API_CALL from "./services";
import Authentication from "./pages/Authentication";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useStore from "./store";



const Router = () => {
  const routing = useRoutes(router);
  return routing;
};

const App = () => {
  const { setUserData } = useStore();
  const setloginStatus = useLoggedInUpdate();
  const loginStatus = useLoggedIn();

  const isLoggedIn = async () => {
    try {
      const { data: res } = await API_CALL.isLoggedIn();
      setUserData(res.data.user)

      if ((res.data.user.role === "user") || res.data.user.role === "admin") setloginStatus("loggedin");
    } catch (error: unknown) {
      setloginStatus("loggedout");
    }
  };

  useEffect(() => {
    isLoggedIn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      {loginStatus === "loggedout" && <Authentication />}

      {loginStatus === "loggedin" && <Router />}
    </Fragment>
  )
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <LoggedInProvider>
        <CssBaseline />
        <ThemeProvider theme={theme}>
          <ToastContainer />
          <App />
        </ThemeProvider>
      </LoggedInProvider>
    </BrowserRouter>
  </React.StrictMode>
);
