import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import Tag from "../tag";
import useStore from "../../store";

interface Plan {
  text?: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  tagText?: string;
  descriptionText?: string;
  price?: string;
  selected?: any;
  icon?: string;
  subscriptionType?: string;
}

const PlanCard: React.FC<Plan> = ({
  text,
  tagText,
  descriptionText,
  price,
  icon,
  selected,
  subscriptionType,
  onClick,
}) => {
  const {userData: user} = useStore()
  const isSmallScreen = useMediaQuery("(max-width:600px)");

  return (
    <Box
      position="relative" // Added to allow absolute positioning of the ribbon
      height={"450px"}
      minWidth={"350px"}
      overflow={"hidden"}
      padding={3}
      borderRadius={2}
      sx={{
        background: selected
          ? "linear-gradient(180deg, rgba(237,28,36,1) 0%, rgba(195,38,44,1) 7%, rgba(68,68,68,1) 100%)"
          : "#2B2930",
        transition: "transform 0.3s ease-in-out, background 0.3s ease-in-out",
        "&:hover": {
          cursor: "pointer",
          transform: isSmallScreen?"none":"scale(1.1)",
        },
        width: isSmallScreen ? "100%" : "auto", // Adjust width based on screen size
      }}
      onClick={onClick}
    >
      {user?.subscriptionType && subscriptionType === user.subscriptionType && (
        <Box
          position="absolute"
          top={26}
          right={-38}
          width={150}
          height={26}
          color="white"
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{
            background: "linear-gradient(45deg, #ff0033 0%, #46000c 100%)",
            transform: "rotate(45deg)",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            border: "1px solid rgba(0, 0, 0, 0.1)",
            "&::before": {
              content: '""',
              position: "absolute",
              top: -6,
              left: -6,
              width: 12,
              height: 12,
              bgcolor: "#ff0033",
              border: "1px solid rgba(0, 0, 0, 0.1)",
              transform: "rotate(45deg)",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            },
            "&::after": {
              content: '""',
              position: "absolute",
              bottom: -6,
              right: -6,
              width: 12,
              height: 12,
              bgcolor: "#cc0022",
              border: "1px solid rgba(0, 0, 0, 0.1)",
              transform: "rotate(45deg)",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            },
            "& span": {
              fontSize: "12px",
              fontWeight: "bold",
            },
          }}
        >
          <span>Purchased</span>
        </Box>
      )}
      {/* Ribbon */}

      <Box mt={2}>
        <Tag tagText={tagText} selected={selected} />
      </Box>
      <Box mt={3} display={"flex"} alignItems={"center"}>
        {icon && <img src={icon} alt={text} height={25} width={25} />}
        <Typography variant="h5" fontWeight={600} sx={{ color: "#fff" }}>
          {text} subscription
        </Typography>
      </Box>
      <Box ml={2} width={"80%"}>
        <Typography
          sx={{ color: "#fff", fontSize: "16px", whiteSpace: "nowrap" }}
        >
          Unlimited access for {descriptionText}
        </Typography>
      </Box>
      <Box mt={4} width={"80%"}>
        <Typography
          variant="h2"
          sx={selected ? { color: "#ccc", fontWeight: 600 } : { color: "#fff" }}
        >
          {price}
        </Typography>
      </Box>
      <Box mt={4} width={"80%"}>
        <ul style={{ textAlign: "left", paddingLeft: "16px" }}>
          <li style={{ color: "#fff", fontSize: "16px", marginBottom: "4px" }}>
            Daily workout Videos
          </li>
          <li style={{ color: "#fff", fontSize: "16px", marginBottom: "4px" }}>
            Monitor daily calories
          </li>
          <li style={{ color: "#fff", fontSize: "16px", marginBottom: "4px" }}>
            Customized meal plans
          </li>
          <li style={{ color: "#fff", fontSize: "16px", marginBottom: "4px" }}>
            Community Support
          </li>
        </ul>
      </Box>
    </Box>
  );
};

export default PlanCard;
