import React, { useState } from "react";
import OtpInput from "react-otp-input";
import { Button, Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as path from "../../router/path";
import { AuthProps } from "../Authentication";
import API_CALL from "../../services";
import { useLoggedInUpdate } from "../../utils/loggedInContext";
import { setUser } from "../../utils/session";

function Otp({ actionQuery, navigateToAuth }: AuthProps) {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const setloginStatus = useLoggedInUpdate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const verifyEmail = async () => {
    try {
      const { data: res } = await API_CALL.verifyEmail({ otp });
      setloginStatus("loggedin");
      setUser(res.data.user);
      navigate(path.plansPath);
    } catch (e) {}
  };

  const verifyResetPassword = async () => {
    try {
      await API_CALL.verifyResetPasswordOTP({ otp });
      navigateToAuth({ page: "reset-password", otp });
    } catch (e) {}
  };

  const verify = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (actionQuery === "forgot") {
      await verifyResetPassword();
    } else if (actionQuery === "verify") {
      await verifyEmail();
    }
  };

  return (
    <Box
      component="form"
      onSubmit={verify}
      noValidate
      sx={{ mt: 1, width: "100%" }}
    >
      <Typography color={"#ccc"} mb={2} variant={isSmallScreen ? "subtitle1" : "h6"}>
        Enter the Otp that has been sent to your email address
      </Typography>
      <Box display={"flex"} justifyContent={"center"}>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          renderSeparator={<span>-</span>}
          renderInput={(props) => <input {...props} />}
          shouldAutoFocus
          inputStyle={{
            flex: 1,
            height: isSmallScreen ? "40px" : "60px",
            fontSize: isSmallScreen ? "16px" : "24px",
            backgroundColor: "transparent",
            border: "2px solid #ee1c25 ",
            color: "#ccc",
          }}
          containerStyle={{
            marginBottom: isSmallScreen ? "80px" : "160px",
            columnGap: "12px",
            width: "100%",
          }}
        />
      </Box>

      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="error"
        sx={{ mb: 2, height: "44px", fontWeight: 600 }}
      >
        Verify
      </Button>
      <Box
            sx={{
              mb: -2,
              backgroundColor: "#383542c4",
              p: 2,
              textAlign: "center",
              width: "100%",
            }}
          >
            <Button
              onClick={() => navigateToAuth({ page: "login" })}
              sx={{
                color: "#f1f1ff",
                fontSize: "14px",
                textTransform: "none",
                "&:hover": {
                  color: "#ADD8E6", // Light blue shade
                  cursor: "pointer",
                  textDecoration: "underline",
                  backgroundColor: "inherit", // To prevent background change on hover
                },
              }}
            >
              Back to login
            </Button>
      
      </Box>
    </Box>
  );
}

export default Otp;
