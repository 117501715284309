import { Box, Typography } from "@mui/material";

const Tag = ({tagText,selected}:any) => {
  return (
    <Box width={120} height={26}  borderRadius={8}display={"flex"} alignItems={"center"} bgcolor={selected?"#2B2930":"#ED1C24"}
    
    
    >
      <Typography  color={"#fff"}fontWeight={600} sx={{ml:1.5,fontSize:"16px"}}>{tagText}</Typography>
    </Box>
  );
};
export default Tag;
