import React, { useState } from "react";
import { TextField, Button, Grid, Box, Typography, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as path from "../../router/path";
import { useLoggedInUpdate } from "../../utils/loggedInContext";
import API_CALL from "../../services";
import { AuthProps } from "../Authentication";
import notify from "../../utils/notify";
import useStore from "../../store";

const Login = ({ navigateToAuth }: AuthProps): JSX.Element => {
  const navigate = useNavigate();
  const { setUserData } = useStore();
  const setLoginStatus = useLoggedInUpdate();
  const [loading, setLoading] = useState<boolean>(false);
  const [alert, setAlert] = useState<{
    state: "success" | "error" | "info" | "warning" | null;
    message: string;
  }>({ state: null, message: "" });

  const clearAlert = () => {
    setAlert({ state: null, message: "" });
  };

  const login = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData(e.target as HTMLFormElement);

    const data = {
      email: formData.get("email") as string,
      password: formData.get("password") as string,
    };

    if (!data.email) {
      setAlert({ state: "error", message: "Email cannot be empty" });
    } else if (!data.password) {
      setAlert({ state: "error", message: "Password cannot be empty" });
    } else {
      setLoading(true);
      try {
        const { data: res } = await API_CALL.login({
          email: data.email,
          password: data.password,
        });

        if (res.message) setAlert({ state: "info", message: res.message });
        if (String(res.message).includes("verify")) {
          notify("info", res.message);
          navigateToAuth({ page: "otp", action: "verify" });
        }
        if (res.data.user.isVerified) {
          const { data: res } = await API_CALL.isLoggedIn();
          if ((res.data.user.role === "user") || res.data.user.role === "admin") {
            setLoginStatus("loggedin");
            setUserData (res.data.user)
            navigate(path.plansPath); // Redirect after successful login
          }
        }
        setLoading(false);
      } catch (err: any) {
        console.log(err, "error");
        notify("error", err.response?.data?.message);

        setAlert({
          state: "error",
          message: err.response?.data?.message || "Login failed",
        });
        setLoading(false);
      }
    }
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      // style={{ minHeight: "100vh" }}

      width={"100%"}
    >
      <Grid
        item
        xs={12}
        sm={8}
        md={6}
        lg={4}
        sx={{
          width: "100%",
        }}
      >
        <Box
          component="form"
          method="post"
          onSubmit={login}
          onChange={clearAlert}
          sx={{ width: "100%" }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            InputProps={{ sx: { color: "white" } }}
            InputLabelProps={{ sx: { color: "white" } }}
            variant="outlined"
          />

          <TextField
            margin="normal"
            required
            fullWidth
            autoComplete="off"
            name="password"
            label="Password"
            type="password"
            id="password"
            InputProps={{ sx: { color: "white" } }}
            InputLabelProps={{ sx: { color: "white" } }}
            variant="outlined"
          />

          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{
              mt: 2,
              mb: 2,
              bgcolor: "#ED1C24",
              color: "#fff",
              fontWeight: 600,
              height: "52px",
            }}
            disabled={loading}
          >
            {loading ? "Logging in" : "Login"}
          </Button>

          {!loading && alert.state && (
            <Alert severity={alert.state} sx={{ mb: 2 }}>
              {alert.message}
            </Alert>
          )}

          <Grid container justifyContent="center" mb={2} mt={2}>
            <Button
              onClick={() => navigateToAuth({ page: "forgot-password" })}
              sx={{
                color: "#f1f1ff",
                mb: -1,
                fontSize: "14px",
                textTransform: "none",
                "&:hover": {
                  color: "#ADD8E6", // Light blue shade
                  cursor: "pointer",
                  textDecoration: "underline",
                  backgroundColor: "inherit", // To prevent background change on hover
                },
              }}
            >
              Forgot your password?
            </Button>
          </Grid>

          <Box
            sx={{
              mb: -2,
              backgroundColor: "#383542c4",
              p: 2,
              textAlign: "center",
              width: "100%",
            }}
          >
            <Typography sx={{ color: "#f1f1ff", fontSize: "14px" }}>
              Don't have an account?{" "}
              <Button
                onClick={() => navigateToAuth({ page: "register" })}
                sx={{
                  color: "#f1f1ff",
                  fontSize: "14px",
                  textTransform: "none",
                  "&:hover": {
                    textDecoration: "underline",
                  backgroundColor: "inherit", // To prevent background change on hover

                  },
                }}
              >
                Sign Up
              </Button>
            </Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Login;
