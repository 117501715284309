import React, { useEffect, useMemo, useState } from "react";
import Login from "../Auth/login";
import Register from "../Auth/register";
import logo from "../../assets/img/GFWU_logo-06_2.png";
import { useLocation, useNavigate } from "react-router-dom";
import ForgotPassword from "../Auth/forgotPassword";
import { Box, Container, Typography } from "@mui/material";
import Otp from "../Auth/otp";
import ResetPassword from "../Auth/resetPassword";
import { capitalize } from "lodash";

export type AuthPage =
  | "login"
  | "register"
  | "forgot-password"
  | "reset-password"
  | "otp";
export type AuthAction = "verify" | "forgot";

export interface AuthNavigate {
  page: AuthPage;
  action?: AuthAction;
  otp?: string;
}

export interface AuthProps {
  navigateToAuth: (pages: AuthNavigate) => void;
  actionQuery?: AuthAction;
}

function Authentication() {
  const location = useLocation();
  const navigate = useNavigate();
  const search = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);

  const pageQuery = (search.get("page") as AuthPage) || "login";
  const actionQuery = search.get("action") as AuthAction;
  const [page, setPage] = useState<AuthPage>(pageQuery);

  useEffect(() => {
    setPage(pageQuery || "login");
  }, [pageQuery]);

  const navigateToAuth = (pages: AuthNavigate) => {
    const page = `?page=${pages.page}`;
    const action = pages.action ? `&action=${pages.action}` : "";
    const otp = pages.otp ? `&otp=${pages.otp}` : "";
    navigate({ search: page + action + otp });
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        minHeight: "100vh",
        
      }}
    
    >
      <Box   display={"flex"} justifyContent={"center"}>
        <img
          src={logo}
          alt="GoFitWithUs Logo"
          style={{ width: "300px", marginTop: "32px" }}
        />
      </Box>
      <Container maxWidth="sm">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            backgroundColor: "#2B2930",
            boxShadow: 1,
            borderRadius: 2,
            padding: "24px",
            color: "#fff",

          }}

        >
          <Typography
            component="h1"
            variant="h4"
            fontWeight={600}
            sx={{ mb: 4, mt: 3, width: "100%", textAlign: "center" ,



            }}
          >
            {(pageQuery || "")
              .split("-")
              .map((val) => capitalize(val))
              .join(" ")}
          </Typography>

          {page === "login" && <Login navigateToAuth={navigateToAuth} />}
          {page === "register" && <Register navigateToAuth={navigateToAuth} />}
          {page === "forgot-password" && (
            <ForgotPassword navigateToAuth={navigateToAuth} />
          )}
          {page === "otp" && (
            <Otp actionQuery={actionQuery} navigateToAuth={navigateToAuth} />
          )}
          {page === "reset-password" && (
            <ResetPassword
              actionQuery={actionQuery}
              navigateToAuth={navigateToAuth}
            />
          )}
        </Box>
      </Container>
      <Box></Box>
    </Box>
  );
}

export default Authentication;
