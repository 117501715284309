import { pickBy } from "lodash";


// import formDataApi from "./formDataInstance";
import jsonApi from "./jsonInstance";
import { CreatePaymentInterface, ForgotInterface, LoginInterface, OTPInterface, RegisterInterface, ResetInterface } from "../interface/auth"
import { SubscriptionType } from "../store";

export const userKey = "6iu8j3guht44yre65h";
export const encryptionPassword = "@@0Linknbit99!!";

export const queryParams = (params: { [key: string]: string }) => (pickBy(params) ? `?${new URLSearchParams(pickBy(params)).toString()}` : "");

const API_CALL = {
  // auth
  isLoggedIn: async () => jsonApi.get(`auth/logged-in`),
  login: async (data: LoginInterface) => jsonApi.post(`auth/login`, data),
  logout: async () => jsonApi.post(`auth/logout`),
  forgotPassword: async (data: ForgotInterface) => jsonApi.post(`auth/forgot-password`, data),
  resetPassword: async (data: ResetInterface) => jsonApi.post(`auth/reset-password`, data),
  register: async (data: RegisterInterface) => jsonApi.post(`auth/signup`, data),
  verifyEmail: async (data: OTPInterface) => await jsonApi.post("auth/verify-email", data),
  verifyResetPasswordOTP: async (data: OTPInterface) => await jsonApi.post("auth/verify-reset-password-otp", data),
  createPayment: async (data: CreatePaymentInterface) => await jsonApi.post("stripe/pay", data, { headers: { Authorization: `Bearer ${process.env.REACT_APP_STRIPE_SECRET_KEY}` } }),
  confirmPayment: async () => await jsonApi.post("stripe/confirm"),
  subscribe: async (subscriptionType: SubscriptionType | null) => await jsonApi.post("stripe/subscribe", { subscriptionType }),
  manageSubscriptions: async () => await jsonApi.post("stripe/manage-subscriptions"),
};

export default API_CALL;
