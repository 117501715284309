import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Modal,
  Typography,
  Grid,
  useMediaQuery,
  ListItemButton,
  List,
} from "@mui/material";
import PlanCard from "../../components/planCard";
import logo from "../../assets/img/GFWU_logo-06_2.png";
import PaymentIcon from '@mui/icons-material/Payment';
import basicLogo from "../../assets/img/basic.png";
import standardLogo from "../../assets/img/standard.png";
import PremiumLogo from "../../assets/img/lifetime.png";
import advancedLogo from "../../assets/img/advanced.png";
import Button from "@mui/material/Button";
import { useLoggedInUpdate } from "../../utils/loggedInContext";
import API_CALL from "../../services";
import { useLocation, useNavigate } from "react-router-dom";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Logout from "@mui/icons-material/Logout";
import showToast from "../../utils/notify";
import { capitalize } from "lodash";
import useStore, { PaymentProvider, SubscriptionType } from "../../store";

interface Package {
  text: string;
  slug: SubscriptionType;
  tagText: string;
  descriptionText: string;
  price: string;
  img: string;
}

const Plans: React.FC = () => {
  const { userData: currentUser, setUserData } = useStore();
  const [selected, setSelected] = useState<SubscriptionType | null>(currentUser?.subscriptionType || null);
  const setloginStatus = useLoggedInUpdate();
  const location = useLocation();
  const setLoginStatus = useLoggedInUpdate();
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  const packages: Package[] = [
    {
      text: "Monthly",
      slug: SubscriptionType.MONTHLY,
      tagText: "Basic",
      descriptionText: "a Month",
      price: "$20.00",
      img: basicLogo
    },
    {
      text: "6 Months",
      slug: SubscriptionType.HALF_YEARLY,
      tagText: "Standard",
      descriptionText: "6 Months",
      price: "$102.00",
      img: standardLogo
    },
    {
      text: "Yearly",
      slug: SubscriptionType.YEARLY,
      tagText: "Advanced",
      descriptionText: "Year",
      price: "$180.00",
      img: advancedLogo
    },
    {
      text: "Life time",
      slug: SubscriptionType.LIFETIME,
      tagText: "Premium",
      descriptionText: "Life time",
      price: "$800.00",
      img: PremiumLogo
    },
  ];

  const handlePlan = (index: SubscriptionType): any => {
    setSelected(index);
  };

  const handleLogout = async () => {
    try {
      const res = await API_CALL.logout();
      if (res.data.status === "success") {
        setUserData(null)
        setLoginStatus("loggedout");
        navigate("/");
      }
    } catch (err: any) {
      return err.response;
    }
  };

  const toggleModal = () => {
    setOpenModal((prev) => !prev);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const formatSubscriptionType = (subscriptionType?: SubscriptionType) => capitalize(subscriptionType).split("_").join(" ")

  const isSmallScreen = useMediaQuery("(max-width:600px)");

  const subscribe = async () => {
    try {
      const res: any = await API_CALL.subscribe(selected);

      if (res.data.session.url) window.location = res.data.session.url
    } catch (e: any) {
      if (e.response?.data.message) {
        showToast("error", e.response.data.message);
      }
      if (e.response.status === 409) {
        window.location = e.response.data.data.redirectUrl;
      }
    }
  }

  const isLoggedIn = async () => {
    try {
      const { data: res } = await API_CALL.isLoggedIn();
      setUserData(res.data.user);
      if (currentUser?.paymentProvider === PaymentProvider.STRIPE) {

        setSelected(currentUser?.subscriptionType?.toLowerCase() as never || null)
      }

      if ((res.data.user.role === "user") || res.data.user.role === "admin") setloginStatus("loggedin");
    } catch (error: unknown) {
      setloginStatus("loggedout");
    }
  };

  const confirmPayment = async () => {
    try {
      await API_CALL.confirmPayment();

      void isLoggedIn();
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    const search = new URLSearchParams(location.search)
    if (search.get("action") === "success") {
      void confirmPayment();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const openStripePortal = async () => {
    try {
      const { data: res }: any = await API_CALL.manageSubscriptions();

      if (res.data.redirectUrl) window.location = res.data.redirectUrl;
    } catch (e: any) {
      if (e.response?.data.message) {
        showToast("error", e.response.data.message);
      }
    }
  }

  return (
    <Box
      maxHeight="100vh"
      margin={0}
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"space-between"}
      alignItems={"center"}
      p={isSmallScreen ? 2 : 0}
    >
      <Grid
        container
        alignItems="center"
        justifyContent="center"
        sx={{ width: "100%", mb: 3 }}
      >
        <Grid item xs={6} sm={6} display="flex">
          <Box display={"flex"} alignItems={"center"} ml={3} flex={1}>
            <img
              src={logo}
              alt="GoFitWithUs Logo"
              style={{
                width: isSmallScreen ? "200px" : "300px",
                marginTop: "32px",
              }}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={6}
          sm={6}
          display="flex"
          justifyContent={{ xs: "flex-end", sm: "flex-end" }}
          sx={{
            position: { xs: "relative", sm: "initial" },
            mt: { xs: 2, sm: 0 },
          }}
        >
          <Tooltip title="Account settings">
            <IconButton
              onClick={handleMenuClick}
              size="small"
              aria-controls={menuOpen ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={menuOpen ? "true" : undefined}
              sx={{
                "&:hover": {
                  backgroundColor: "inherit",
                },
              }}
            >
              <ListItem>
                <ListItemAvatar>
                  <Avatar src="/broken-image.jpg" />
                </ListItemAvatar>
                {!isSmallScreen && (
                  <ListItemText primary="User" secondary={currentUser?.email} />
                )}
              </ListItem>
            </IconButton>
          </Tooltip>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={menuOpen}
            onClose={handleMenuClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                },
                "&::before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            <MenuItem
              sx={{
                p: 0,
                "&:hover": {
                  backgroundColor: "transparent",
                  cursor: "default",
                },
              }}
            >
              <ListItemButton onClick={openStripePortal}>
                <ListItemIcon>
                  <PaymentIcon />
                </ListItemIcon>
                <ListItemText primary="Stripe Portal" />
              </ListItemButton>
            </MenuItem>
            <Divider />
            <MenuItem
              sx={{
                "&:hover": {
                  backgroundColor: "transparent",
                  cursor: "default",
                },
              }}
            >
              <List>
                <ListItem>
                  <ListItemText
                    sx={{ p: 0 }}
                    primary="Subscription type"
                    secondary={
                      formatSubscriptionType(currentUser?.subscriptionType) ||
                      "Not subscribed"
                    }
                  />
                </ListItem>
              </List>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
      <Box>
        <Box textAlign={"center"} mb={8}>
          <Typography variant="h3" color={"#fff"} mb={3} mt={2}>
            Choose your plan
          </Typography>
          <Typography variant="h5" color={"#fff"}>
            Get Access to unlimited daily workouts, meal plans and fitness
            journey like no where else
          </Typography>
        </Box>
        <Grid container spacing={4} justifyContent={"center"}>
          {packages.map((pkg, id) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={id}>
              <PlanCard
                text={pkg.text}
                tagText={pkg.tagText}
                descriptionText={pkg.descriptionText}
                price={pkg.price}
                icon={pkg.img}
                selected={selected?.toLowerCase() === pkg.slug.toLowerCase()}
                onClick={() => {
                  handlePlan(pkg.slug);
                }}
                subscriptionType={pkg?.slug}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
      <Box width={350} mt={4}>
        <Button
          variant="contained"
          color="error"
          fullWidth
          onClick={subscribe}
          sx={{
            borderRadius: 2,
            height: 46,
            fontWeight: 600,
            fontSize: 16,
            mt: 4,
            mb: isSmallScreen ? 2 : 0,
          }}
        >
          Subscribe now
        </Button>
      </Box>
      <Modal
        open={openModal}
        onClose={toggleModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          width={{ xs: "90%", sm: "550px" }}
          height="auto"
          bgcolor="#ccc"
          padding={4}
          textAlign={"center"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-evenly"}
          borderRadius={2}
          position={"relative"}
        >
          <Box position="absolute" top={8} right={8}>
            <IconButton onClick={toggleModal} style={{ color: "#000" }}>
              <HighlightOffIcon />
            </IconButton>
          </Box>
          <Typography
            id="modal-modal-title"
            variant="h5"
            component="h2"
            color="#000"
            fontWeight={600}
            mb={2}
          >
            Enter Payment Information
          </Typography>
        </Box>
      </Modal>
    </Box>
  );
};

export default Plans;
