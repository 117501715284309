import React, { useState } from "react";
import {
  TextField,
  Button,
  AlertColor,
  Alert,
  Box,
  Typography,
} from "@mui/material";
import { RegisterInterface } from "../../interface/auth";
import notify from "../../utils/notify";

import API_CALL from "../../services";
import { AuthProps } from "../Authentication";

const Register = ({ navigateToAuth }: AuthProps): JSX.Element => {
  type Error = {
    state?: null | AlertColor;
    message?: string;
  };
  const [alert, setAlert] = useState<Error>({ state: null, message: "" });
  const [loading, setLoading] = useState<boolean>(false);

  const register = async (e: React.FormEvent<HTMLFormElement>) => {
    const formData = new FormData(e.target as HTMLFormElement);
    const data: RegisterInterface = {
      email: formData.get("email") as string,
      password: formData.get("password") as string,
      passwordConfirm: formData.get("passwordConfirm") as string,
    };
    e.preventDefault();
    if (!data.email) {
      setAlert({ state: "error", message: "Email cannot be empty" });
    } else if (!data.password) {
      setAlert({ state: "error", message: "Password cannot be empty" });
    } else if (!data.passwordConfirm) {
      setAlert({ state: "error", message: "Confirm password cannot be empty" });
    } else {
      setLoading(true);
      try {
        const res = await API_CALL.register(data);
        setLoading(false);
        setAlert({ state: "success", message: res.data.message });
        // setScreen("otp")
        notify("warning", res.data.message);

        navigateToAuth({ page: "otp", action: "verify" });
        // navigate(path.otpPath)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.log(err);
        if (err.response.data.message.includes("E11000")) {
          setAlert({ state: "error", message: "User already exists" });
        } else {
          setAlert({ state: "error", message: err.response.data.message });
        }
        setLoading(false);
      }
    }
  };

  return (
    <form onSubmit={register} noValidate style={{ width: "100%" }}>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        sx={{
          "& .MuiInputBase-root": {
            backgroundColor: "transparent",
            color: "white",
          },
          "& .MuiInputLabel-root": {
            color: "white",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "white",
            },
            "&:hover fieldset": {
              borderColor: "white",
            },
            "&.Mui-focused fieldset": {
              borderColor: "white",
            },
          },
        }}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="password"
        label="Password"
        name="password"
        type="password"
        sx={{
          "& .MuiInputBase-root": {
            backgroundColor: "transparent",
            color: "white",
          },
          "& .MuiInputLabel-root": {
            color: "white",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "white",
            },
            "&:hover fieldset": {
              borderColor: "white",
            },
            "&.Mui-focused fieldset": {
              borderColor: "white",
            },
          },
        }}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="passwordConfirm"
        label="Confirm Password"
        name="passwordConfirm"
        type="password"
        sx={{
          "& .MuiInputBase-root": {
            backgroundColor: "transparent",
            color: "white",
          },
          "& .MuiInputLabel-root": {
            color: "white",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "white",
            },
            "&:hover fieldset": {
              borderColor: "white",
            },
            "&.Mui-focused fieldset": {
              borderColor: "white",
            },
          },
        }}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        sx={{
          mt: 3,
          mb: 2,
          bgcolor: "#ED1C24",
          height: "52px",
          textTransform: "none",

          color: "#fff",
          fontWeight: 600,
        }}
      >
        {loading ? "Signing Up" : "Sign Up"}
      </Button>
      {!loading && alert?.state && (
        <Alert variant="standard" severity={alert.state}>
          {alert.message}
        </Alert>
      )}
      <Box
        sx={{
          mb: -2,
          backgroundColor: "#383542c4",
          p: 2,
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center  ",
          alignItems: "center"
        }}
      >
        <Typography>Already have an account?</Typography>
        <Button
          onClick={() => navigateToAuth({ page: "login" })}
          sx={{
            color: "#f1f1ff",

            fontSize: "14px",
            textTransform: "none",

            "&:hover": {
              color: "#ADD8E6", // Light blue shade
              cursor: "pointer",
              textDecoration: "underline",
              backgroundColor: "inherit", // To prevent background change on hover
            },
          }}
        >
          Sign in
        </Button>

      </Box>
    </form>
  );
};

export default Register;
