import { createTheme } from "@mui/material";

const fontFamily = "Poppins, sans-serif";

const theme = createTheme({
  typography: {
    fontFamily
  },
  palette: {
    mode: "dark"
  }
});

export default theme;
