import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  AlertColor,
  Alert,
  Typography,
} from "@mui/material";

// Import your logo URL

import API_CALL from "../../services";
import { AuthProps } from "../Authentication";
type Error = {
  state?: null | AlertColor;
  message?: string;
};
const ForgotPassword = ({ navigateToAuth }: AuthProps): JSX.Element => {
  const [alert, setAlert] = useState<Error>({ state: null, message: "" });
  const [loading, setLoading] = useState<boolean>(false);

  const forgotPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);

    const data = {
      email: formData.get("email") as string,
    };
    if (!data.email) {
      setAlert({ state: "error", message: "Email cannot be empty" });
    } else {
      if (e) setLoading(true);
      try {
        const res = await API_CALL.forgotPassword({ email: data.email });
        navigateToAuth({ page: "otp", action: "forgot" });
        // navigate(path.otpPath)
        setAlert({ state: "success", message: res.data.message });
        if (e) setLoading(false);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        setLoading(false);
        setAlert({ state: "error", message: err.response.data.message });
      }
    }
  };
  const clearAlert = () => {
    setAlert((prev) => ({ state: prev === null ? undefined : null }));
  };

  return (
    <Box
      component="form"
      sx={{ mt: 1, width: "100%" }}
      onSubmit={forgotPassword}
      onChange={clearAlert}
      method="post"
    >
      <Typography>
        Enter the email address associated with your account
      </Typography>
      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label="Email Address"
        name="email"
        autoComplete="email"
        type="email"
        autoFocus
        sx={{
          mt: 4,
          "& .MuiInputBase-root": {
            backgroundColor: "transparent",
            color: "white",
          },
          "& .MuiInputLabel-root": {
            color: "white",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: "white",
            },
            "&:hover fieldset": {
              borderColor: "white",
            },
            "&.Mui-focused fieldset": {
              borderColor: "white",
            },
          },
        }}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="error"
        sx={{
          mt: 3,
          mb: 2,
          height: "52px",
          textTransform: "none",

          color: "#fff",
          fontWeight: 600,
        }}
        disabled={loading}
      >
        {loading ? "Sending email" : "Reset Your Password"}
      </Button>
      {!loading && alert?.state && (
        <Alert variant="standard" severity={alert.state}>
          {alert.message}
        </Alert>
      )}
      <Box
        sx={{
          mb: -2,
          backgroundColor: "#383542c4",
          p: 2,
          textAlign: "center",
          width: "100%",
          display: "flex",
          justifyContent: "center  ",
          alignItems: "center",
        }}
      >
     
          <Button
            onClick={() => navigateToAuth({ page: "login" })}
            sx={{
              color: "#f1f1ff",

              fontSize: "14px",
              textTransform: "none",
              "&:hover": {
                color: "#ADD8E6", // Light blue shade
                cursor: "pointer",
                textDecoration: "underline",
                backgroundColor: "inherit", // To prevent background change on hover
              },
            }}
          >
            Back to login
          </Button>
        </Box>
      
    </Box>
  );
};

export default ForgotPassword;
