import { Outlet, Navigate } from "react-router-dom";
import { loginPath } from "./path";

const useAuth = () => {
  const user = { isLoggedIn: true };
  return user && user.isLoggedIn;
};

function PrivateRoutes() {
  const isAuth = useAuth();
  return isAuth ? <Outlet /> : <Navigate to={loginPath} />;
}

export default PrivateRoutes;


